<script>
	import { ref } from "vue";
	import { repgen } from "@/firebase";
	import clientModel from "@/data/clientModel";
	import ClientDataService from "@/services/ClientDataService";
	import RepgenDataService from "@/services/RepgenDataService";
	import repgenSections from "@/data/RepgenSections";
	import SlackService from "@/services/SlackService";
	import EmployeeService from "@/services/EmployeeService";

	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
	import { doc, writeBatch, collection, getDocs } from "@firebase/firestore";
	import { getCurrentUser } from "@/firebase";

	export default {
		components: {
			Dialog,
			DialogOverlay,
			DialogTitle,
			TransitionChild,
			TransitionRoot,
		},
		data() {
			return {
				engDD: "eng",
				target: "",
				client: "",
				contactEmail: "",
				employeeList: {},
				people: [],
				slackBaseAPI: "https://slack.com/api/",
				slackChannel: "",
				repgenSections: repgenSections,
				clientModel: clientModel,
				invitees: [],
				spLink: "",
				slackLinked: false,
				employeeUnlinked: false,
			};
		},
		mounted() {
			SlackService.isLinked().then(linked => this.slackLinked = linked);

			EmployeeService.getEmployeeList().then(employeeList => {
				this.employeeList = employeeList
				for(const [_, employee] of Object.entries(employeeList)) {
					if(employee.slackID == "") {
						this.employeeUnlinked = true;
						break;
					}
				}
			})
		},
		methods: {
			closeModal() {
				this.open = false;
				this.$emit("close-modal", false);
			},
			addEngagement() {
				this.clientModel.spLink = `https://akfpartnerscom.sharepoint.com/:f:/r/sites/AKFPartnersKMS/Shared%20Documents/Client%20Engagements/${encodeURIComponent(this.target)}`
				
				if (this.engDD == "dd") {
					const target = this.target.replace(/\s+/g, "-").toLowerCase();
					const client = this.client.replace(/\s+/g, "-").toLowerCase();
					this.clientModel.slackChannelName = `${this.engDD}-${target}-${client}`;
				} else {
					const target = this.target.replace(/\s+/g, "-").toLowerCase();
					this.clientModel.slackChannelName = `${this.engDD}-${target}`;
				}

				this.addNewClient();
				this.createSlackChannel();
				this.createSharepointFolders();
				this.createRepgen();
			},
			createSlackChannel() {
				// ** create slack channel
				SlackService.createChannel(this.clientModel.slackChannelName)
					.then((response) => {
						this.slackChannel = response.data.channel.id;
						this.inviteUsers();
						this.sendWelcomeMessage();
					})
					.catch((e) => {
						console.log(e)
						this.errors.push(e);
					});
			},
			createSharepointFolders() {
				// ** create client and dataroom folders
				let siteId = "2b09010f-4739-421a-b896-3db7f95927b4";
				this.$httpMS
					.post(`/sites/${siteId}/drive/items/root:/Client Engagements:/children`, {
						"name": this.target,
						"folder": {}
					})
					.then((response) => {
						console.log(response)
						this.$httpMS
							.post(`/sites/${siteId}/drive/items/root:/Client Engagements/${this.target}:/children`, {
								"name": "Data Room",
								"folder": {}
							}).then()
							.catch((e) => {
								console.log(e)
								this.errors.push(e);
							});
					})
					.catch((e) => {
						console.log(e)
						this.errors.push(e);
					});
			},
			sendWelcomeMessage() {
				SlackService.sendMessage(this.slackChannel, `<${this.clientModel.spLink}|Sharepoint Folder>`)
					.then((response) => {
						console.log(response);
						SlackService.pinMessage(this.slackChannel, response.data.ts)
							.then()
							.catch((e) => {
								console.log(e)
								this.errors.push(e);
							});
					}).catch((e) => {
						console.log(e)
						this.errors.push(e);
					});
			},
			async inviteUsers() {
				const currentId = (await getCurrentUser()).uid
				let users = this.people.filter(person => person != currentId).map((person) => this.employeeList[person].slackID).join(", ");
				if(users.length < 0) return // No users to invite

				SlackService.inviteUsers(this.slackChannel, users)
					.then()
					.catch((e) => {
						console.log(e)
						this.errors.push(e);
					});
			},
			addNewClient() {
				const created = new Date();
				let data = {
					id: this.target,
					date: created,
					deleted: false,
				};

				// @/services/ClientDataService.js
				ClientDataService.createClient(data, this.target)
					.then(() => {
						console.log("Created new client");
					})
					.catch((e) => {
						console.log(e);
					});

				this.clientModel.akfers = this.people;

				ClientDataService.createEng(this.target, this.clientModel, this.engDD, this.contactEmail)
					.then(() => {
						console.log("Created new client engagement");
					})
					.catch((e) => {
						console.log(e);
					});

				// this.addQuestionsToClient();

				this.$router.push({ path: `/client/${this.target}/${this.clientModel.engID}` });
			},
			async createRepgen() {
				const created = new Date();
				let data = {
					id: this.target,
					date: created,
					deleted: false,
				};
				console.log(data)
				// @/services/RepgenDataService.js
				await RepgenDataService.create(data, this.target)
					.then(() => {
						console.log("Created new client repgen successfully!");
					})
					.catch((e) => {
						console.log(e);
					});
				// await clientsDB.doc(this.clientName).set(data);

				this.addQuestionsToClient();
			},
			async addQuestionsToClient() {
				var i;
				var title;
				var batch = writeBatch(repgen)
				var batchWrites = 0

				for (i = 0; i < this.repgenSections.length; i++) {
					this.questions = [];
					title = this.repgenSections[i].title;
					const docs = await getDocs(collection(repgen, "questions", "sections", title));
					docs.forEach((doc) => {
						const question = doc.data();
						this.questions.push(question);
					});

					for (var j = 0; j < this.questions.length; j++) {
						batch.set(doc(collection(repgen, "clients", this.target, title)), {
							rowId: this.questions[j].rowId,
							question: this.questions[j].question,
							order: this.questions[j].order,
							active: this.questions[j].active,
							likert: null,
							tips: this.questions[j].tips,
							recommended: true,
							comments: [],
						});
						batchWrites++;
						if(batchWrites >= 500) {
							batch.commit();
							batch = writeBatch(repgen)
							batchWrites = 0
						}
					}
				}
				if(batchWrites > 0) batch.commit()
			},
			engSwitch() {
				if (this.engDD == "dd") {
					this.engDD = "eng";
				} else {
					this.engDD = "dd";
				}
			},
		},
		setup() {
			const open = ref(true);

			return {
				open,
			};
		},
	};
</script>

<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="closeModal()">
			<div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
				</TransitionChild>

				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg md:max-w-xl sm:w-full sm:p-6"
					>
						<div>
							<div class="mt-3 text-center sm:mt-5">
								<DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
									New Client Engagement
								</DialogTitle>
								<div class="mt-4 text-left">
									<p class="text-sm text-gray-500">
										Add your new client here. This will create the Slack channel, add team members to it, and create
										Sharepoint folder, RepGen...everything you need.
									</p>
									<div v-if="!slackLinked" class="text-red-500">
										Slack channel creation unavailable. Please go to your profile to link your slack account.
									</div>
									<div class="flex mt-8 text-gray-500">
										<span class="mr-2" :class="[engDD == 'eng' ? 'text-gray-400' : 'text-blue-500 font-bold']">DD</span>
										<!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
										<button
											type="button"
											class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out bg-gray-200 border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											role="switch"
											@click="engSwitch()"
											:class="[
												engDD == 'eng' ? 'bg-blue-600' : 'bg-blue-600',
												'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
											]"
											aria-checked="false"
										>
											<span class="sr-only">Use setting</span>
											<!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
											<span
												aria-hidden="true"
												class="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0"
												:class="[
													engDD == 'eng' ? 'translate-x-5' : 'translate-x-0',
													'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
												]"
											></span>
										</button>
										<span :class="[engDD == 'eng' ? 'text-blue-500 font-bold' : 'text-gray-400']" class="ml-2"
											>ENG</span
										>
									</div>
									<div class="mt-6">
										<label v-if="engDD == 'dd'" class="block text-sm font-medium text-gray-600">Target Name</label>
										<label v-else class="block text-sm font-medium text-gray-600">Client Name</label>
										<div class="mt-1">
											<input
												type="text"
												name="target"
												v-model="target"
												class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
												placeholder="e.g. Bush's Baked Beans"
											/>
										</div>
									</div>

									<div class="mt-6" v-if="engDD == 'dd'">
										<label for="email" class="block text-sm font-medium text-gray-600">Client Name</label>
										<div class="mt-1">
											<input
												type="text"
												name="client"
												v-model="client"
												class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
												placeholder="e.g. Stripes"
											/>
										</div>
									</div>
									<div class="mt-6">
										<label for="email" class="block text-sm font-medium text-gray-600">Point of Contact Email</label>
										<div class="mt-1">
											<input
												type="text"
												name="target"
												v-model="contactEmail"
												class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
												placeholder="e.g. your@mom.com"
											/>
										</div>
									</div>
									<fieldset class="mt-6">
										<legend class="text-sm font-medium text-gray-600">
											Who is part of the engagement
										</legend>
										<div class="grid grid-cols-2 mt-2 gap-y-1 gap-x-5">
											<div
												v-for="(employee, employeeID) in employeeList"
												:key="employeeID"
												class="relative flex items-start"
											>
												<div class="flex-1 min-w-0 text-sm">
													<label :for="`employee-${employeeID}`" :class="[employee.slackID == '' ? 'text-red-500' : 'text-gray-600']" class="text-gray-600 select-none ">{{
														employee.name
													}}</label>
												</div>
												<div class="flex items-center h-5 ml-3">
													<input
														v-model="people"
														:value="employeeID"
														:id="`employee-${employeeID}`"
														:name="`employee-${employeeID}`"
														type="checkbox"
														class="w-5 h-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
													/>
												</div>
											</div>
										</div>
									</fieldset>
									<div v-if="employeeUnlinked" class="text-red-500 my-2">
										People in red do not have their slack accounts linked, and will not be automatically added to the created slack channel
									</div>
								</div>
							</div>
						</div>

						<div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
							<button
								type="button"
								class="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
								@click="addEngagement()"
							>
								Add New Client
							</button>
							<button
								type="button"
								class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
								@click="closeModal()"
								ref="cancelButtonRef"
							>
								Cancel
							</button>
						</div>
						<!-- <pre>

						{{ clientModel }}
						</pre> -->
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<style scoped>
	.w-11 {
		width: 2.75rem;
	}
</style>
