import { repgen } from "@/firebase";
import checklists from "@/data/checklists";
import { collection, doc, getDoc, query, setDoc, updateDoc, where } from "@firebase/firestore";

const ddChecklist = [0, 1, 3];
const engChecklist = [0, 2, 3];

class ClientDataService {
	getOneClient(client) {
		const q = query(collection(repgen, "client-engagements", client, "engagements"), where("active", "==", true))
		return getDoc(q)
	}
	getEngagement(clientName, engID) {
		return getDoc(doc(repgen, "client-engagements", clientName, "engagements", engID));
	}
	createClient(data, client) {
		return setDoc(doc(repgen, "client-engagements", client), data);
	}

	createEng(client, clientModel, engDD, email) {
		clientModel.client = client;
		clientModel.engType = engDD;
		clientModel.contact.email = email;
		clientModel.engID = new Date().valueOf().toString();
		console.log(engDD);
		if (engDD == "eng") {
			for (var i = 0; i < engChecklist.length; i++) {
				clientModel.checklists.push(checklists[engChecklist[i]]);
			}
		} else {
			for (var j = 0; j < ddChecklist.length; j++) {
				clientModel.checklists.push(checklists[ddChecklist[j]]);
			}
		}
		console.log(clientModel.engID);
		return setDoc(doc(repgen, "client-engagements", client, "engagements", clientModel.engID), clientModel);
	}

	updateDate(client, engID, model, newDate) {
		return updateDoc(doc(repgen, "client-engagements", client, "engagements", engID), {
			[`${model}`]: newDate,
		});
	}

	updateDetailInput(client, engID, model, entry) {
		return updateDoc(doc(repgen, "client-engagements", client, "engagements", engID), {
			[`${model}`]: entry,
		});
	}

	updateChecklist(client, engID, checklistArray, checklistID, ID) {
		if (checklistArray[checklistID.phase].checklist[ID].done == true) {
			checklistArray[checklistID.phase].checklist[ID].done = true;
		} else {
			checklistArray[checklistID.phase].checklist[ID].done = false;
		}
		console.log("update checklist item");
		return updateDoc(doc(repgen, "client-engagements", client, "engagements", engID), {
			checklists: checklistArray,
		});
	}

	updateClientPhase(client, engID, phase) {
		phase = phase + 1;
		return updateDoc(doc(repgen, "client-engagements", client, "engagements", engID), {
			phase: phase,
		});
	}

	update(id, value) {
		return updateDoc(doc(repgen, "client-engagements", id), value);
	}
}

export default new ClientDataService();
