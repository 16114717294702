import { repgen } from "@/firebase";
import { doc, setDoc, updateDoc } from "@firebase/firestore";

class RepGenDataService {
  // getAll() {
  //   return dbRepgen;
  // }

  create(data, client) {
    return setDoc(doc(repgen, "clients", client), data);
  }

  update(id, value) {
    return updateDoc(doc(repgen, "clients", id), value);
  }
}

export default new RepGenDataService();
