import { collection, getDocs, query, where } from "@firebase/firestore";
import { repgen } from "@/firebase";

class EmployeeService {
    employeeList = null
    async getEmployeeList() {
        if(this.employeeList) return this.employeeList;
        const snapshot = await getDocs(query(collection(repgen, "employees"), where("active", "==", true)))
        this.employeeList = {}
        snapshot.forEach(doc => this.employeeList[doc.id] = doc.data())
        return this.employeeList
    }
}

export default new EmployeeService()